
import {Vue, Component, Prop} from 'vue-property-decorator'

export const statuses : any = {
    DRAFT         : {text: "Draft", color : "warning", textColor: "black"},
    PENDING       : {text: "Pending", color : "warning", textColor: "black"},
    CONFIRMED     : {text: "Confirmed", color : "success", textColor: "white"},
    IN_PROGRESS   : {text: "In progress", color : "success", textColor: "white"},
    ON_HOLD       : {text: "On hold", color : "warning", textColor: "black"},
    COMPLETED      : {text: "Completed", color : "black", textColor: "white"},
    CANCELLED      : {text: "Cancelled", color : "error", textColor: "white"}
  }

@Component({components: {}})
export default class AppointmentStatusChip extends Vue {
  @Prop({ required : true }) readonly status!: any

  get statusData() {
    return statuses[this.status]
  }

}
