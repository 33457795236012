
import {Component, Prop} from 'vue-property-decorator'
import BasePanel from '@/components/base/BasePanel.vue'
import { BaseAppointmentComponent } from '../base/BaseMixins'
import JobLogFormDialog from './JobLogFormDialog.vue'
import JobLogNavigatorDialog from './JobLogNavigatorDialog.vue'
import JobStatusChip from './JobStatusChip.vue'
import AppointmentStatusChip from '../scheduling/AppointmentStatusChip.vue'
import { DateTime } from 'luxon'
import BaseOptionsMenu from '../base/BaseOptionsMenu.vue'
import { AppointmentResource, MaintenanceJobResource } from '@/resources'
import AppointmentEditDialog from "@/components/scheduling/AppointmentEditDialog.vue";

@Component({components: {AppointmentEditDialog, BaseOptionsMenu, AppointmentStatusChip, JobLogNavigatorDialog, BasePanel, JobLogFormDialog, JobStatusChip}})
export default class JobStatusPanelAppointment extends BaseAppointmentComponent {

  @Prop({required: true}) maintenanceJobResource !: MaintenanceJobResource
  appointmentDialog : boolean = false
  hideDateTime : boolean = false
  selectedAppointment !: AppointmentResource | null
  deleting : boolean = false

  allJobs : MaintenanceJobResource[] = []

  postEntityUpdate() {
    if (this.rdata) {
      this.resource.jobs.getAssociation({projection: "maintenanceJobSummary"}, false).then((results) => {
        this.allJobs.splice(0)
        this.allJobs.push(...results)
      })
    }
  }

  get otherJobs() {
    return this.allJobs.length > 1 ? this.allJobs.filter(j => j.data().id != this.rdata.id) : []
  }

  jobCompletedCancelled(job: MaintenanceJobResource) {
    const COMPLETED_CANCELLED = [MaintenanceJobResource.STATUS_CLOSED_COMPLETED, MaintenanceJobResource.STATUS_CLOSED_CANCELLED]
    return COMPLETED_CANCELLED.includes(job.data().status)
  }

  /**
   * We can add if no appointment already, and the job is not completed/cancelled
   */
  get canAdd() {
    return !this.rdata && !this.jobCompletedCancelled(this.maintenanceJobResource)
  }

  get canDelete() {
    return !this.jobCompletedCancelled(this.maintenanceJobResource)
  }

  /**
   * We can edit this appointment if there is at least one 
   * job not COMPLETED/CANCELLED
   */
  get canEditAppointment() {
    if (this.allJobs) {
      for (let job of this.allJobs) {
        if (!this.jobCompletedCancelled(job)) {
          return true
        }
      }
    }
    return false
  }

  addAppointment() {
    // TODO handle errors
    this.selectedAppointment = null
    this.appointmentDialog = true
  }

  editAppointment() {
    // TODO handle errors
    this.selectedAppointment = this.resource
    this.appointmentDialog = true
  }

  deleteAppointment() {
    // TODO errors
    this.deleting = true
    this.resource.delete().then(() => {
      this.$emit('deleted', true)
    }).finally(() => this.deleting = false)
  }

  showDateTimeDialog(hideDateTime : boolean) {
    this.hideDateTime = hideDateTime
    this.selectedAppointment = this.resource.fullResource()
    this.appointmentDialog = true
  }

  calDate(d : any) {
    return d ? DateTime.fromISO(d).toFormat('yyyy-MM-dd') : ''
  }
  appointmentDateStr(d : any) {
    return d ? DateTime.fromISO(d).toLocaleString(DateTime.DATE_MED) : ''
  }
  appointmentTimeStr(d : any) {
    return d ? DateTime.fromISO(d).toLocaleString(DateTime.TIME_SIMPLE) : ''
  }
}
