
import {Component, InjectReactive} from 'vue-property-decorator'
import BasePanel from '@/components/base/BasePanel.vue'
import { BaseMaintenanceJobComponent } from '../base/BaseMixins'
import JobFormDialog from '../jobs/JobFormDialog.vue'
import JobStatusChip from './JobStatusChip.vue'
import { DateTime } from 'luxon'
import BaseOptionsMenu from '../base/BaseOptionsMenu.vue'
import JobStatusPanelAppointment from "@/components/jobs/JobStatusPanelAppointment.vue"
import { AppointmentResource, MaintenanceJobResource, PropertyResource } from '@/resources'
import { MODE_ADD_EDIT } from '../jobs/JobFormDialog.vue'

@Component({components: {JobFormDialog, JobStatusPanelAppointment, BaseOptionsMenu, BasePanel, JobStatusChip}})
export default class JobStatusPanel extends BaseMaintenanceJobComponent {
  @InjectReactive("propertyResource") propertyResource !: PropertyResource
  
  appointmentResource : AppointmentResource | null = null
  jobDialog : boolean = false
  jobFormMode = MODE_ADD_EDIT
  jobToEdit : MaintenanceJobResource | null = null

  get workRatio() {
    return parseInt("" + (this.rdata.actualDuration / this.rdata.estimatedDuration) * 100)
  }

  jobDate(d : any) {
    return d ? DateTime.fromISO(d).toLocaleString(DateTime.DATE_MED) : ''
  }
  calDate(d : any) {
    return d ? DateTime.fromISO(d).toFormat('yyyy-MM-dd') : ''
  }
  appointmentDate(d : any) {
    return d ? DateTime.fromISO(d).toLocaleString(DateTime.DATE_MED) : ''
  }
  appointmentTime(d : any) {
    return d ? DateTime.fromISO(d).toLocaleString(DateTime.TIME_SIMPLE) : ''
  }

  editJob() {
    this.jobFormMode = MODE_ADD_EDIT
    this.jobToEdit = this.resource
    this.jobDialog = true
  }


  postEntityUpdate() {
    // TODO we assume only one appt per job right now
    this.resource.appointments.getAssociation({}, false).then((appts) => {      
      if (appts.length > 0) {
        this.appointmentResource = appts[0]
      }
    })
  }

}
