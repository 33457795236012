
import {Component, InjectReactive, ProvideReactive} from 'vue-property-decorator'
import { BaseMaintenanceJobComponent } from '../base/BaseMixins'
import JobLogsPanel from '../jobs/JobLogsPanel.vue'
import JobCloseCancelDialog from "../jobs/JobCloseCancelDialog.vue";
import JobFormDialog from "../jobs/JobFormDialog.vue";
import JobStatusPanel from '../jobs/JobStatusPanel.vue'
import HealthReportsList from '../healthreports/HealthReportsList.vue'
import { AppointmentResource, MaintenanceJobResource, PropertyMaintenanceSystemsResource, PropertyResource } from '@/resources'
import AppointmentStatusChip from '../scheduling/AppointmentStatusChip.vue'
import BasePanel from '../base/BasePanel.vue'
import JobStatusChipSelector from '../jobs/JobStatusChipSelector.vue'
import InstantUpdateEnumSelector from "../forms/InstantUpdateEnumSelector.vue";
import AppointmentStatusChipSelector from '../scheduling/AppointmentStatusChipSelector.vue';

@Component({components: {AppointmentStatusChipSelector, InstantUpdateEnumSelector, JobCloseCancelDialog, JobFormDialog, JobStatusChipSelector, AppointmentStatusChip, BasePanel, HealthReportsList, JobLogsPanel, JobStatusPanel}})
export default class PropertyMaintenanceJobDetail extends BaseMaintenanceJobComponent {

  @InjectReactive("propertyResource") propertyResource !: PropertyResource
  @InjectReactive("propertyMaintenanceSystem") propertyMaintenanceSystem !: PropertyMaintenanceSystemsResource
  @ProvideReactive() maintenanceJobResource = this.resource

  appointmentData : any = null
  appointmentResource : any = null


  addJobDialog : boolean = false
  closeCancelDialog : boolean = false
  closeCancelDialogCancelling : boolean = false
 
  /**
   * Job is closed if appointment is finished.
   * @param jobStatus 
   */
  isJobClosed(jobStatus : string) {
    return AppointmentResource.STATUSES_FINISHED.includes(jobStatus)
  }

  // TODO same as AppointmentJobDetailsPanel.vue
  changeJobStatus(status : any) {
    // if now closed, show that dialog
    if (MaintenanceJobResource.STATUS_CLOSED.includes(status)) {
      this.closeCancelDialogCancelling = (status == MaintenanceJobResource.STATUS_CLOSED_CANCELLED)
      this.closeCancelDialog = true
    }
    else {
      // TODO errors
      this.resource.mergePatch({status : status})
    }
  }
      
  closeJob(scheduleNext : boolean) {
    this.closeCancelDialog = false
    if (scheduleNext) {
      this.addJobDialog = true
    }
  }

  gotoNewJob(j : any) {
    this.$router.push({ name: 'job', params: {jid:j.data.id, pid : j.data.property_id, pmsId: j.data.propertyMaintenanceSystem_id}})
  }

  postEntityUpdate() {
    // TODO we assume only one appt per job right now
    this.resource.appointments.getAssociation({projection: 'appointmentDetail'}, false).then((appts) => {      
      if (appts.length > 0) {
        this.appointmentResource = new AppointmentResource(appts[0].data().id)
        this.appointmentData = appts[0].data()
      }
    })
  }

}
