
import { AppointmentResource } from '@/resources'
import {Component} from 'vue-property-decorator'
import { BaseAppointmentComponent } from '../base/BaseMixins'
import BaseOptionsMenu from '../base/BaseOptionsMenu.vue'
import AppointmentStatusChip from './AppointmentStatusChip.vue'
import {statuses} from './AppointmentStatusChip.vue'

@Component({components: {BaseOptionsMenu, AppointmentStatusChip}})
export default class AppointmentStatusChipSelector extends BaseAppointmentComponent {
  
  updatingStatus : boolean = false

  get closedCancelled() {
    return AppointmentResource.STATUSES_FINISHED.includes(this.rdata.status)
  }

  /**
   * Valid statuses are not completed/cancelled which can only be done
   * via updating the job.
   */
  get validStatuses() {
    // if closed, closed from job so no can change.
    if (this.closedCancelled) {
      return []
    }
    return Object.keys(statuses).filter(s => !AppointmentResource.STATUSES_FINISHED.includes(s) && s != this.rdata.status)
  }


  selectStatus(status : any) {
    this.updatingStatus = true
    this.resource.mergePatch({status : status}).finally(() => this.updatingStatus = false)
  }

}
